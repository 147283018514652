import React from "react"
import Contact from "../components/contact/Contact"
import Layout from "../components/layout/Layout"
import SecondaryHeader from "../components/sections/SecondaryHeader"
import Form from "../components/contact/Form"
import Operator from "../components/Operator"
import SEO from "../components/SEO"
import seotext from "../constants/seotext"
import Map from "../components/Map"
import Benefits from "../components/sections/Benefits"
import { graphql, useStaticQuery } from "gatsby"

export default ({ pageContext }) => {

  const image = useStaticQuery(graphql`
    query {
      kontakt: contentfulAsset(title: { eq: "CANR8324" }) {
        fluid(maxWidth: 1600, quality: 50) {
          ...GatsbyContentfulFluid
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Kontakt" description={seotext.kontakt} />
      <SecondaryHeader
        pageContext={pageContext}
        disable={["/kontakt"]}
        heading=""
        bold="Kontakt"
        image={image.kontakt.fluid}
      />
      <section className="contact-section">
        <div className="container">
          <div className="flex-contact">
            <Contact />
            <Form />
          </div>
        </div>
      </section>
      <Map />
      <section>
        <div className="container u-margin-bot">
          <Operator />
        </div>
      </section>
      <Benefits place="alone" />
    </Layout>
  )
}
