import { useState } from "react"

const useForm = defaults => {
  const [values, setValues] = useState(defaults)
  const [loading, setLoading] = useState(null)
  const [msg, setMsg] = useState("")
  const [err, setErr] = useState(false)

  const updateValue = e => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    })
  }

  const submitForm = async e => {
    e.preventDefault()
    setLoading(true)
    setMsg(null)
    setErr(false)

    const toSend = { values, captcha }

    const res = await fetch(`${process.env.GATSBY_FUNCTIONS_URL}/sendForm`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
        "Content-Type": "application/json",
        "Access-Control-Allow-Methods": "*",
        "Access-Control-Max-Age": "2592000",
        "Access-Control-Allow-Credentials": "true",
      },
      body: JSON.stringify(toSend),
    })

    const text = JSON.parse(await res.text())

    if (res.status >= 400 && res.status < 600) {
      setErr(true)
      setLoading(false)
      setMsg(text.message)
    } else {
      setLoading(false)
      setMsg(text.message)
      setErr(false)
      setValues({
        name: "",
        mail: "",
        phone: "",
        msg: "",
      })
    }
  }

  return { values, updateValue, submitForm, loading, msg, err }
}

export default useForm
