import React from "react"
import { insertMap } from "../functions/functions"
import styles from "./Map.module.scss"
import useDeviceDetect from "../hooks/useDeviceDetect"

const Map = () => {
  const isMobile = useDeviceDetect()
  return (
    <div
      id="map"
      className={`${isMobile ? styles.mapMobile : styles.mapDesktop}`}
      dangerouslySetInnerHTML={insertMap()}
    />
  )
}

export default Map
