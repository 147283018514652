import React from "react"
import Button from "../Button"
import styles from "./Form.module.scss"
import Fade from "react-reveal/Fade"
import useForm from "../../hooks/useForm"

const Form = () => {
  const {
    values,
    updateValue,
    submitForm,
    msg,
    loading,
    err,
  } = useForm({
    name: "",
    mail: "",
    phone: "",
    msg: "",
  })

  return (
    <Fade bottom duration={700}>
      <form id="form" className={styles.form} onSubmit={submitForm}>
        <h3>Kontaktní formulář</h3>
        <input
          name="name"
          placeholder="Jméno"
          className={styles.input}
          type="text"
          autoComplete="off"
          onChange={updateValue}
          value={values.name}
        />
        <div className={styles.inputRow}>
          <input
            name="mail"
            placeholder="Email*"
            className={styles.input}
            type="email"
            autoComplete="off"
            onChange={updateValue}
            value={values.mail}
          />
          <input
            name="phone"
            placeholder="Telefon"
            className={styles.input}
            type="text"
            autoComplete="off"
            onChange={updateValue}
            value={values.phone}
          />
        </div>
        <textarea
          name="msg"
          placeholder="Zpráva*"
          rows="4"
          className={`${styles.textarea} ${styles.input}`}
          type="message"
          autoComplete="off"
          onChange={updateValue}
          value={values.msg}
        />
        <Button
          type="submit"
          text={loading ? "Odesílám..." : "Odeslat"}
          disabled={loading}
        />
        {msg && <div className={err ? styles.error : styles.succes}>{msg}</div>}
      </form>
    </Fade>
  )
}

export default Form
