import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Fade from "react-reveal/Fade"

const getOperator = graphql`
  query {
    allContentfulProvozovatel {
      edges {
        node {
          name
          adress
          ic
          list
        }
      }
    }
  }
`

const Operator = () => {
  const { name, adress, ic, evNumber, list } = useStaticQuery(
    getOperator
  ).allContentfulProvozovatel.edges[0].node

  return (
    <Fade bottom duration={700}>
      <div>
        <h3>Provozovatel</h3>
        Jméno: {name} <br />
        Adresa: {adress} <br />
        IČ: {ic} <br />
        {list} <br />
      </div>
    </Fade>
  )
}

export default Operator
