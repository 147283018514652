import React from "react"
import styles from "./Benefits.module.scss"
import { HiBadgeCheck } from "react-icons/hi"
import BackgroundImage from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"
import Fade from "react-reveal/Fade"
import PropTypes from 'prop-types';
import benefits from "../../constants/benefits"

const Benefits = ({ place }) => {
  const data = useStaticQuery(graphql`
    query {
      image: contentfulAsset(title: { eq: "CANR8588" }) {
        fluid(maxWidth: 1600, quality: 50) {
          ...GatsbyContentfulFluid
        }
      }
      imageTop: contentfulAsset(title: { eq: "CANR8280" }) {
        fluid(maxWidth: 1000, quality: 60) {
          ...GatsbyContentfulFluid
        }
      }
    }
  `)

  return (
    <section
      className={`${styles.section} ${
        place === "alone" ? styles.sectionAlone : ""
      }`}
    >
      <div className={`${styles.benefits}`}>
        <div className={styles.underlay} />
        <div className={styles.underlayLeft} />
        <div className={styles.text}>
          <Fade bottom duration={700}>
            <h2>
              Proč <span className="heading-bold">Kovoart?</span>
            </h2>
          </Fade>
          {benefits.map((benefit, i) => {
            return (
              <Fade key={i} bottom duration={700}>
                <div className={styles.benefit}>
                  <div className={styles.description}>
                    <HiBadgeCheck />
                    <div className={styles.title}>{benefit.title}</div>
                  </div>
                  <p>
                    {benefit.text}
                  </p>
                </div>
              </Fade>
            )
          })}
        </div>
        <BackgroundImage className={styles.image} fluid={data.image.fluid} />
        <BackgroundImage className={styles.imagesec} fluid={data.imageTop.fluid}
        />
      </div>
    </section>
  )
}

export default Benefits

Benefits.propTypes = {
  place: PropTypes.string
}