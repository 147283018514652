import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styles from "./Contact.module.scss"
import ContactBox from "./ContactBox"

const getAbout = graphql`
  query {
    allContentfulKontakty {
      edges {
        node {
          name
          adress
          telefon
          mail
        }
      }
    }
  }
`

export const Contact = () => {
  const data = useStaticQuery(getAbout);
  const contacts = data.allContentfulKontakty.edges[0].node;

  return (
    <div>
      <div className={styles.contacts}>
        <ContactBox contact={contacts.name} icon="name" />
        <a href={`tel:${contacts.telefon}`}>
          <ContactBox contact={contacts.telefon} icon="phone" delay={150} />
        </a>
        <a href={`mailto:${contacts.mail}`}>
          <ContactBox contact={contacts.mail} icon="mail" delay={300} />
        </a>
        <a href={`#map`}>
          <ContactBox contact={contacts.adress} icon="adress" delay={450} />
        </a>
      </div>
    </div>
  )
}

export default Contact
