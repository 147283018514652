export default [
  {
    title: "Prvotřídní kvalita",
    text: "Díky kvalitním vstupním materíálům vždy poskytujeme prvotřídní kvalitu zhotovených výrobků. Více než 15 let praxe promítneme do Vašeho výrobku."
  }, 
  {
    title: "Originalita",
    text: "Pokaždé se snažíme nabídnout originální řešení, ve kterém můžeme odrazit naši kreativitu, ale které zároveň koresponduje i s představou zákazníka."
  },
  {
    title: "Zakázková výroba na míru",
    text: "Zpravidla nabízíme zakázkovou a ruční výrobu na míru od návrhu, zaměření, zpracování výkresů až po montáž."
  }
]