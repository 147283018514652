import React from "react"
import styles from "./SecondaryHeader.module.scss"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import { FaHome } from "react-icons/fa"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from "gatsby"

const getImage = graphql`
  query {
    contentfulAsset(title: { eq: "HeroImg" }) {
      fluid(maxWidth: 1800, quality: 50) {
        ...GatsbyContentfulFluid
      }
    }
  }`

const SecondaryHeader = ({
  heading,
  bold,
  pageContext,
  crumbLabel,
  disable,
  image,
}) => {
  const defaultImage = useStaticQuery(getImage)

  let crumbs
  if (pageContext) {
    crumbs = pageContext.breadcrumb.crumbs
  }

  const disableLinks = disable ? disable : ""

  return (
    <header className={`${styles.header}`}>
      <BackgroundImage
        fluid={image ? image : defaultImage.contentfulAsset.fluid}
        className={image ? styles.image : styles.defaultImage}
      >
        <div className={`${styles.wrapper} container`}>
          <h1>
            {heading} <span className="heading-bold">{bold}</span>
          </h1>
          <div className={styles.breadcrumb}>
            <Link to="/">
              <FaHome className={styles.icon} />
            </Link>
            {pageContext && (
              <Breadcrumb
                crumbs={crumbs}
                crumbLabel={crumbLabel}
                disableLinks={disableLinks}
                crumbSeparator=" / "
              />
            )}
          </div>
        </div>
      </BackgroundImage>
    </header>
  )
}

export default SecondaryHeader

SecondaryHeader.propTypes = {
  heading: PropTypes.string,
  bold: PropTypes.string,
  pageContext: PropTypes.object,
  crumbLabel: PropTypes.object,
  disable: PropTypes.array,
  image: PropTypes.object,
}